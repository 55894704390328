import uuidv4 from "uuid/v4";
import {
  IApiResponse,
  INewNode,
  INewOrganization,
  INewOrganizationUser,
  INewUser,
  IObject,
  IUserOrganization,
  IDidCartItem,
  VBSSettings
} from "../Interfaces/Common";
import {
  Action,
  DispatchEventLoading,
  DispatchEventAlert,
  DispatchEventRemoveAlert,
  DispatchConfig,
  IConfigObject,
  IConfigTable
} from "../Interfaces/Redux";

import axios from "axios";
import {AUTH_LOCAL_STORAGE_KEY} from "../Helpers/Constants";
import {i18n} from "../i18n";
import {INewApp} from "connectel-shared/interfaces/App";
import moment from "moment";
import { parseJwt } from "../Reducers/Auth";
import config from "../config";
import { removeItem } from "../cookies";
import { BenchmarkDuration, SiteBenchmarkMetrics } from "connectel-shared/constants/Benchmark";

 export function setStore(_store: any): void {
  store = _store;
}

let store: any;

export const HOST: string = config.apiUrl;

const startSessionExpireHandler = () => {
  const tokenExpiryInterval = setInterval(() => {
    const decoded: any = parseJwt(store.getState()?.auth?.token)
    if(moment().unix() - decoded.exp > 0) {
      clearInterval(tokenExpiryInterval);
      store.dispatch({
        payload: {
          message: "Session timeout. Please login again.",
          onCancel: () => window.location.reload(),
          onConfirm: () => window.location.href = config.loginUrl,
          uuid: uuidv4()
        },
        type: Action.ALERT_ERROR,
      });
    }
  }, 60000);
}

startSessionExpireHandler();
export async function NumbersReq<T>(
  url: string,
  body: IObject,
  method: "get" | "post" | "put" | "delete" = "get",
  customOptions: {
    displayErrors?: boolean;
  } = {}
) {
  const options = {
    displayErrors: true,

    ...customOptions
  };

  return await Req<T>(
    url,
    body,
    method,
    loading,
    null,
    null,
    options.displayErrors,
    {}
  );
}

export async function Req<T>(
  url: string,
  body: IObject,
  method: "get" | "post" | "put" | "delete" = "get",
  statusChange: Function = null,
  _token: string = null,
  filename: string = null,
  displayErrors: boolean = true,
  customHeaders: IObject = {}
): Promise<T> {
  const uuid: string = uuidv4();
  const {dispatch, getState} = store;

  /*
  if (statusChange)
    dispatch(statusChange(true));
    */

  if (statusChange) statusChange(true, uuid);

  const {token} = _token
    ? {token: _token}
    : !getState
    ? {token: null}
    : getState().auth;

  const headers: any = {
    Authorization: `Bearer ${token}`,
    ...customHeaders
  };

  if (!filename) headers["Content-Type"] = "application/json";

  const config: any = {
    data: body,
    headers,
    method,
    url
  };

  if (filename) config.responseType = "blob";

  let res: any;
  try {
    res = await axios(config);
  } catch (err) {
    if (statusChange) statusChange(false, uuid);

    if (
      err.response &&
      err.response.status === 401 &&
      !!~url.indexOf("power-report-api")
    ) {
      alertError("You are not authorized to view this page!");
      dispatch({
        payload: {isAuth: false, token: "unauthorized", user: ""},
        type: Action.TOKEN_RESP
      });
      removeItem(AUTH_LOCAL_STORAGE_KEY);
      window.location.pathname = "/portal/unauthorized";
      return Promise.reject(err.response);
    } else {
      if (displayErrors) {
        alertError(getErrorMessage(err) || i18n.t("SOMETHING_WENT_WRONG"));
      }
    }
    return Promise.reject(err.response);
  }

  /*
  if (statusChange)
    dispatch(statusChange(false));
    */
  if (statusChange) statusChange(false, uuid);

  if (filename) {
    const url: any = window.URL.createObjectURL(new Blob([res.data]));
    const link: any = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    return res;
  }
  return res.data;
}

function getErrorMessage(err): any {
  let errorMessage = err.response;
  if(err?.response?.data?.data?.[0]?.message?.includes("jwt expired")){
    errorMessage = "Session expired! Please login again.";
  }
  return errorMessage;
}

export function validateToken(token: string): Promise<void> {
  return new Promise<void>((res, rej) => {
    Req<{data: {organizations: Array<any>; token: string; user: any; tokens:any;}}>(
      `${HOST}auth/validate`,
      {},
      "get",
      loading,
      token
    ).then(({data: {user, organizations,tokens}}) => {
      store.dispatch({
        payload: {isAuth: true, token: token,tokens:tokens, organizations, user},
        type: Action.TOKEN_RESP
      });
      reload();
      res();
    }, rej);
  });
}

export async function reload(): Promise<void> {
  fetchNodes().then((res) => {
    store.dispatch({
      payload: res.data,
      type: Action.NODES
    });
  });
  fetchUsers().then((res) => {
    store.dispatch({
      payload: res.data,
      type: Action.USERS
    });
  });
}

export async function getSyncRequestData(page:number,size:number){
  const token = JSON.parse(localStorage.getItem("connectel-auth")).token;
  return await Req<any>(`${HOST}sync_request/fetch-sync-requests/68?page=${page}&&size=${size}`, null, "get", loading,token);
}


export async function fetchNodes(isFlatten : boolean = false): Promise<IApiResponse<Array<any>>> {
  return await Req<any>(
    `${HOST}node/list?isFlatten=${isFlatten}`,
    null,
    "get",
    loading
  );
}

export async function fetchUsers(): Promise<IApiResponse<Array<any>>> {
  return await Req<any>(`${HOST}user/list`, null, "get", loading);
}

export async function fetchApps(): Promise<IApiResponse<Array<any>>> {
  return await Req<any>(`${HOST}app/list`, null, "get", loading);
}

export async function fetchOrganizationUsers(
  organization_id: any,
  license: string
): Promise<IApiResponse<Array<any>>> {
  return await Req<any>(
    `${HOST}organization/${organization_id}/users/${license}`,
    null,
    "get",
    loading
  );
}

export async function fetchTokenAs(
  organization_id: number,
  user_id: number,
  service: string
): Promise<IApiResponse<{token: string}>> {
  return await Req<any>(
    `${HOST}auth/organization/${organization_id}/user/${user_id}/token/${service}`,
    null,
    "get",
    loading
  );
}

export async function fetchTrends(noOfDays : number) {
  return await Req<any>(
   `${HOST}sync_request/trends?days=${noOfDays}`,
   null,
   "get",
   loading
  );
}

export async function addOrganization(
  payload: INewOrganization
): Promise<IApiResponse<Array<any>>> {
  payload.parent_id = parseInt(payload.parent_id + "", 10);
  const resp: IApiResponse<Array<any>> = await Req<any>(
    `${HOST}organization`,
    payload,
    "post",
    loading
  );
  reload();
  return resp;
}

export async function updateOrganization(
  payload: INewOrganization
): Promise<IApiResponse<Array<any>>> {
  payload.parent_id = parseInt(payload.parent_id + "", 10);
  const resp: IApiResponse<Array<any>> = await Req<any>(
    `${HOST}organization/${payload.id}`,
    payload,
    "put",
    loading
  );
  reload();
  return resp;
}

export async function deleteOrganization(
  org_id: number
): Promise<IApiResponse<Array<any>>> {
  const resp: IApiResponse<Array<any>> = await Req<any>(
    `${HOST}organization/${org_id}`,
    null,
    "delete",
    loading
  );
  reload();
  return resp;
}

export async function deleteApp(
  app_id: number
): Promise<IApiResponse<Array<any>>> {
  return await Req<any>(`${HOST}app/${app_id}`, null, "delete", loading);
}

export async function addApp(
  payload: INewApp
): Promise<IApiResponse<Array<any>>> {
  return Req<any>(`${HOST}app`, payload, "post", loading);
}

// This function checks the hostName in the payload,
// if hostname does not have '/', then add to it and then return the payload
function addBackSlashToHostName(payload: INewNode) {
  if (payload.hostname.substring(payload.hostname.length - 1) !== "/") {
    payload.hostname = payload.hostname + "/";
  }
  return payload;
}

export async function addNode(
  payload: INewNode
): Promise<IApiResponse<Array<any>>> {
  payload.organization_id = parseInt(payload.organization_id + "", 10);
  payload = addBackSlashToHostName(payload);
  const resp: IApiResponse<Array<any>> = await Req<any>(
    `${HOST}node`,
    payload,
    "post",
    loading
  );
  reload();
  return resp;
}

export async function updateNode(
  payload: INewNode
): Promise<IApiResponse<Array<any>>> {
  payload.organization_id = parseInt(payload.organization_id + "", 10);
  payload = addBackSlashToHostName(payload);
  const resp: IApiResponse<Array<any>> = await Req<any>(
    `${HOST}node/${payload.id}`,
    payload,
    "put",
    loading
  );
  reload();
  return resp;
}

export async function deleteNode(
  org_id: number
): Promise<IApiResponse<Array<any>>> {
  const resp: IApiResponse<Array<any>> = await Req<any>(
    `${HOST}node/${org_id}`,
    null,
    "delete",
    loading
  );
  reload();
  return resp;
}

export async function addUser(
  payload: INewUser
): Promise<IApiResponse<Array<any>>> {
  payload.organization_id = parseInt(payload.organization_id + "", 10);
  const resp: IApiResponse<Array<any>> = await Req<any>(
    `${HOST}user`,
    payload,
    "post",
    loading
  );
  reload();
  return resp;
}

export async function updateUser(
  payload: INewUser
): Promise<IApiResponse<Array<any>>> {
  payload.organization_id = parseInt(payload.organization_id + "", 10);
  const resp: IApiResponse<Array<any>> = await Req<any>(
    `${HOST}user/${payload.id}`,
    payload,
    "put",
    loading
  );
  reload();
  return resp;
}

export async function deleteUser(
  user_id: number,
  toDelete: {survey: boolean, motion: boolean},
): Promise<IApiResponse<Array<any>>> {
  const resp: IApiResponse<Array<any>> = await Req<any>(
    `${HOST}user/${user_id}?survey=${+toDelete.survey}&motion=${+toDelete.motion}`,
    null,
    "delete",
    loading
  );
  reload();
  return resp;
}

export async function unlockUser(
  user_id: number
): Promise<IApiResponse<Array<any>>> {
  const resp: IApiResponse<Array<any>> = await Req<any>(
    `${HOST}user/${user_id}/unlock`,
    null,
    "put",
    loading
  );
  reload();
  return resp;
}

export async function addOrganizationUser(
  payload: INewOrganizationUser
): Promise<IApiResponse<Array<any>>> {
  payload.organization_id = parseInt(payload.organization_id + "", 10);
  const resp: IApiResponse<Array<any>> = await Req<any>(
    `${HOST}user/${payload.user_id}/user_organization`,
    payload,
    "post",
    loading
  );
  reload();
  return resp;
}

export async function removeOrganizationUser(
  user_id: number,
  id: number
): Promise<IApiResponse<Array<any>>> {
  const resp: IApiResponse<Array<any>> = await Req<any>(
    `${HOST}user/${user_id}/user_organization/${id}`,
    null,
    "delete",
    loading
  );
  reload();
  return resp;
}

export async function updateOrganizationUser(
  user_id: number,
  id: number,
  key: string,
  val: any
): Promise<IApiResponse<Array<any>>> {
  const resp: IApiResponse<Array<any>> = await Req<any>(
    `${HOST}user/${user_id}/user_organization/${id}`,
    {[key]: val},
    "put",
    loading
  );
  reload();
  return resp;
}

export const setConfig: DispatchConfig = (payload) => {
  store.dispatch({
    payload,
    type: Action.SET_CONFIG
  });
};

export const setTableSettings = (table: string, settings: IConfigTable) => {
  const config: IConfigObject = store.getState().config;
  config.tables[table] = settings;
  setConfig(config);
};

export async function getNotification(){
 return await Req<any>(
    `${HOST}notifications`,
    null,
    "get",
    loading
  );
}

export async function getAllNotification(params?: Object) {
    return await Req<any>(
        paramsTOQuery(`${HOST}notifications/all`, params),
        null,
        "get",
        loading
    );
}

export async function searchNotifications(params: Object) {
    return await Req<any>(
        paramsTOQuery(`${HOST}notifications/search`, params),
        null,
        "get",
        loading
    );
}

export async function createNotification(payload){
  return await Req<any>(
    `${HOST}notifications`,
    payload,
    "post",
    loading
  );
}

export async function removeNotifications(notificationIds: number[]){
  return await Req<any>(
    `${HOST}notifications`,
   { ids:notificationIds},
    "delete",
    loading
  );
}

export async function fetchSmsNotification(url : string){
  const token = store?.getState()?.auth?.tokens?.skyview;
    return Req<any>(
    `${url}connectel-core/threshold/notification/sms?license=skyview`,
    null,
    "get",
    loading,
    token
  )
}

export async function getOrganizationUsers(
  user_id: number
): Promise<IApiResponse<{
  two_factor_auth: number[];
  organization_users: Array<IUserOrganization>
}>> {
  return await Req<any>(
    `${HOST}user/${user_id}/organization_user`,
    null,
    "get",
    loading
  );
}

export const alertInfo: DispatchEventAlert = (
  message,
  onConfirm = null,
  onCancel = null
): Promise<boolean> => {
  let resolve: (confirmed: boolean) => void;
  const alertPromise = new Promise<boolean>((res, rej) => {
    resolve = res;
  });
  store.dispatch({
    payload: {
      message,
      onCancel,
      onConfirm,
      resolve,
      uuid: uuidv4()
    },
    type: Action.ALERT_INFO
  });
  return alertPromise;
};

export const alertConfirm: DispatchEventAlert = (
  message,
  onConfirm = null,
  onCancel = null,
  inputHandlers: Record<string, Function> = null,
): Promise<boolean> => {
  let resolve: (confirmed: boolean) => void;
  const alertPromise = new Promise<boolean>((res, rej) => {
    resolve = res;
  });

  store.dispatch({
    payload: {
      message,
      onCancel,
      onConfirm,
      resolve,
      uuid: uuidv4(),
      inputHandlers
    },
    type: Action.ALERT_CONFIRM
  });
  return alertPromise;
};

export const alertError: DispatchEventAlert = (
  message,
  onConfirm = null,
  onCancel = null
): Promise<boolean> => {
  let resolve: (confirmed: boolean) => void;
  const alertPromise = new Promise<boolean>((res, rej) => {
    resolve = res;
  });

  store.dispatch({
    payload: {
      message,
      onCancel,
      onConfirm,
      resolve,
      uuid: uuidv4()
    },
    type: Action.ALERT_ERROR
  });
  return alertPromise;
};

export const removeAlert: DispatchEventRemoveAlert = (uuid) => {
  store.dispatch({
    payload: {
      uuid
    },
    type: Action.REMOVE_ALERT
  });
};

export const loading: DispatchEventLoading = (status, uuid) => {
  store.dispatch({
    payload: {
      uuid
    },
    type: status ? Action.LOADING : Action.LOADING_DONE
  });
};

export const saving: DispatchEventLoading = (status, uuid) => {
  store.dispatch({
    payload: {
      uuid
    },
    type: status ? Action.SAVING : Action.LOADING_DONE
  });
};

export const deleting: DispatchEventLoading = (status, uuid) => {
  store.dispatch({
    payload: {
      uuid
    },
    type: status ? Action.DELETING : Action.LOADING_DONE
  });
};

export const updating: DispatchEventLoading = (status, uuid) => {
  store.dispatch({
    payload: {
      uuid
    },
    type: status ? Action.UPDATING : Action.LOADING_DONE
  });
};

export const addToCart = (didCartItem: IDidCartItem): void => {
  store.dispatch({
    payload: didCartItem,
    type: Action.ADD_ITEM_TO_CART
  });
};
export const removeFromCart = (id: string): void => {
  store.dispatch({
    payload: id,
    type: Action.REMOVE_ITEM_FROM_CART
  });
};
export const editCartItem = (id: string, edit: IObject): void => {
  store.dispatch({
    payload: {id, edit},
    type: Action.EDIT_CART_ITEM
  });
};
export const selectOrganization = (orgNr: number): void => {
  store.dispatch({
    payload: orgNr,
    type: Action.SELECT_ORGANIZATION
  });
};

const WinResize: any = () => {
  store.dispatch({
    payload: {
      window: {
        height: window.innerHeight,
        width: window.innerWidth
      }
    },
    type: Action.SET_CONFIG
  });
};
let ResizeTm: any;
window.addEventListener("load", WinResize);
window.addEventListener("resize", () => {
  if (ResizeTm) clearTimeout(ResizeTm);
  ResizeTm = setTimeout(() => {
    WinResize();
  }, 250);
});

const paramsTOQuery = (url: string, params?: Object) => {
    const query = params
        ? Object.keys(params)
              .map(
                  (key) =>
                      encodeURIComponent(key) +
                      "=" +
                      encodeURIComponent(params[key])
              )
              .join("&")
        : "";
        return `${url}?${query}`;
};

export async function checkVbsVoiceBot(orgId: number) {
  return Req<{data: VBSSettings}>(
    `${HOST}node/vbs/settings?orgId=${orgId}`,
    null,
    "get"
  );
}

export async function getBenchmarkData(metricAlias: string, siteId: number = 0, benchmarkDuration: BenchmarkDuration = BenchmarkDuration.LAST_30_DAYS, interval: {from?: string, to?: string} = {}){
  const query = benchmarkDuration === BenchmarkDuration.CUSTOM ? `&from=${interval?.from}&to=${interval?.to}` : '';
  return await Req<{data: SiteBenchmarkMetrics}>(
     `${HOST}metric?metric=${metricAlias}&duration=${benchmarkDuration}${query}&site_id=${siteId}`,
     null,
     "get",
     loading
   );
 }
