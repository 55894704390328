"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getBenchmarkInterval = exports.getBenchmarkDateRange = void 0;
const Benchmark_1 = require("../constants/Benchmark");
const Moment = require("moment");
const moment = require("moment");
exports.getBenchmarkDateRange = (benchmarkDuration, format = 'YYYY-MM-DD', order = "ASC", from, to) => {
    const dateArray = [];
    const startDate = {
        [Benchmark_1.BenchmarkDuration.PREVIOUS_MONTH]: moment().subtract(1, "month").endOf('month'),
        [Benchmark_1.BenchmarkDuration.TODAY_LIVE]: Moment(),
        [Benchmark_1.BenchmarkDuration.THIS_MONTH]: moment().endOf('month'),
        [Benchmark_1.BenchmarkDuration.LAST_7_DAYS]: Moment(),
        [Benchmark_1.BenchmarkDuration.LAST_30_DAYS]: Moment(),
        [Benchmark_1.BenchmarkDuration.LAST_90_DAYS]: Moment(),
        [Benchmark_1.BenchmarkDuration.LAST_180_DAYS]: Moment(),
    };
    const NumberOfDays = {
        [Benchmark_1.BenchmarkDuration.THIS_MONTH]: moment().daysInMonth(),
        [Benchmark_1.BenchmarkDuration.PREVIOUS_MONTH]: moment().subtract(1, "month").daysInMonth(),
        [Benchmark_1.BenchmarkDuration.LAST_7_DAYS]: 7,
        [Benchmark_1.BenchmarkDuration.LAST_30_DAYS]: 30,
        [Benchmark_1.BenchmarkDuration.LAST_90_DAYS]: 90,
        [Benchmark_1.BenchmarkDuration.LAST_180_DAYS]: 180,
    };
    switch (benchmarkDuration) {
        case Benchmark_1.BenchmarkDuration.TODAY_LIVE:
            dateArray.push(startDate[benchmarkDuration].format(format));
            break;
        case Benchmark_1.BenchmarkDuration.THIS_MONTH:
        case Benchmark_1.BenchmarkDuration.PREVIOUS_MONTH:
        case Benchmark_1.BenchmarkDuration.LAST_7_DAYS:
        case Benchmark_1.BenchmarkDuration.LAST_30_DAYS:
        case Benchmark_1.BenchmarkDuration.LAST_90_DAYS:
        case Benchmark_1.BenchmarkDuration.LAST_180_DAYS:
            const daysCount = NumberOfDays[benchmarkDuration];
            for (let i = 0; i < daysCount; i++) {
                dateArray.push(startDate[benchmarkDuration].clone().subtract(i, 'days').format(format));
            }
            break;
        case Benchmark_1.BenchmarkDuration.CUSTOM:
            const dCount = moment(from).daysInMonth();
            for (let i = 0; i < dCount; i++) {
                dateArray.push(moment(to).subtract(i, 'days').format(format));
            }
            break;
        default:
            throw new Error("Unsupported benchmark duration");
    }
    return order === "DESC" ? dateArray : dateArray.reverse();
};
exports.getBenchmarkInterval = (duration) => {
    let from = Moment().startOf('day').format('YYYY-MM-DD 00:00:00');
    let to = Moment().endOf('day').format('YYYY-MM-DD 23:59:59');
    switch (duration) {
        case Benchmark_1.BenchmarkDuration.THIS_MONTH:
            from = Moment().startOf('month').format('YYYY-MM-DD 00:00:00');
            to = Moment().endOf('month').format('YYYY-MM-DD 23:59:59');
            break;
        case Benchmark_1.BenchmarkDuration.PREVIOUS_MONTH:
            from = Moment().subtract(1, "month").startOf('month').format('YYYY-MM-DD 00:00:00');
            to = Moment().subtract(1, "month").endOf('month').format('YYYY-MM-DD 23:59:59');
            break;
        case Benchmark_1.BenchmarkDuration.TODAY_LIVE:
            from = Moment().startOf('day').format('YYYY-MM-DD 00:00:00');
            to = Moment().endOf('day').format('YYYY-MM-DD 23:59:59');
            break;
        case Benchmark_1.BenchmarkDuration.LAST_7_DAYS:
            from = Moment().startOf('day').subtract(7, "days").format('YYYY-MM-DD 00:00:00');
            to = Moment().endOf('day').format('YYYY-MM-DD 23:59:59');
            break;
        case Benchmark_1.BenchmarkDuration.LAST_30_DAYS:
            from = Moment().startOf('day').subtract(30, 'days').format('YYYY-MM-DD 00:00:00');
            to = Moment().endOf('day').format('YYYY-MM-DD 23:59:59');
            break;
        case Benchmark_1.BenchmarkDuration.LAST_90_DAYS:
            from = Moment().startOf('day').subtract(90, 'days').format('YYYY-MM-DD 00:00:00');
            to = Moment().endOf('day').format('YYYY-MM-DD 23:59:59');
            break;
        case Benchmark_1.BenchmarkDuration.LAST_180_DAYS:
            from = Moment().startOf('day').subtract(180, 'days').format('YYYY-MM-DD 00:00:00');
            to = Moment().endOf('day').format('YYYY-MM-DD 23:59:59');
            break;
    }
    return {
        from,
        to
    };
};
